import { rest } from 'msw'

import { apiHost } from 'consts'
import db from 'MockApi/db'

const clientsHandler = rest.get(`${apiHost}/clients`, (_req, res, ctx) => {
    return res(ctx.json(db.client.getAll()))
})

export default clientsHandler
