import { locations } from '.'
import { Db } from '../Db'

export const ldLocations: Db['ldLocation'][] = [
    ...locations.map(location => ({
        ...location,
        '@id': `/api/production_sites/${location.id}`,
        '@type': 'ProdutctionSite',
    })),
]
