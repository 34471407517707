import { types } from '.'
import { Db } from '../Db'

export const ldTypes: Db['ldType'][] = [
    ...types.map(type => ({
        ...type,
        '@id': `"/api/service_types/${type.code}"`,
        '@type': 'ServiceType',
    })),
]
