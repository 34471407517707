import { rest } from 'msw'

import { apiHost } from 'consts'
// import db from 'MockApi/db'

const postServiceHandler = rest.post(
    `${apiHost}/services`,
    (_req, res, ctx) => {
        // console.log({ req })

        return res(ctx.json({}))
        // return res(ctx.json(db.location.getAll()))
    },
)

export default postServiceHandler
