import {
    loginHandler,
    meHandler,
    resetPasswordHandler,
    requestPasswordHandler,
} from './unauthenticatedApp'
import {
    clientsHandler,
    locationsHandler,
    serviceTypesHandler,
    campaignsHandler,
    postServiceHandler,
    serviceDetailHandler,
} from './authenticatedApp'

const commonHandlers = [
    loginHandler,
    meHandler,
    requestPasswordHandler,
    resetPasswordHandler,
    clientsHandler,
    serviceTypesHandler,
    locationsHandler,
    campaignsHandler,
    postServiceHandler,
    serviceDetailHandler,
]
export const browserHandlers = [...commonHandlers]
export const serverHandlers = [...commonHandlers]
