import { primaryKey, oneOf, manyOf, nullable } from '@mswjs/data'
import { Db } from './Db'
import { datatype, name, company, date } from 'faker'
import { UserRole } from 'Types/UserRole'

const models: Db = {
    user: {
        id: primaryKey(datatype.uuid),
        email: String,
        roles: () => [UserRole.Admin, UserRole.User],
        username: name.findName,
    },
    client: {
        id: primaryKey(datatype.uuid),
        name: company.companyName,
        created: date.recent,
    },
    type: {
        code: primaryKey(String),
        name: String,
    },
    location: {
        id: primaryKey(datatype.uuid),
        name: String,
        source: String,
        emissions: Number,
        carbonEmissions: Number,
        type: String,
    },
    campaign: { id: primaryKey(datatype.uuid), name: String, status: Number },
    product: {
        id: primaryKey(String),
        name: String,
        type: oneOf('type'),
        location: oneOf('location'),
        distance: Number,
        volume: Number,
        emissions: Number,
        created: String,
        updated: nullable(String),
    },
    service: {
        id: primaryKey(String),
        type: oneOf('type'),
        name: String,
        campaign: oneOf('campaign'),
        start: String,
        end: String,
        client: oneOf('client'),
        products: manyOf('product'),
        location: oneOf('location', { nullable: true }),
        created: String,
        updated: nullable(String),
        emissions: Number,
    },

    // -------------- //
    // ld+json types  //
    // -------------- //

    ldLocation: {
        '@id': String,
        '@type': String,
        id: primaryKey(String),
        name: String,
        emissions: Number,
    },
    ldClient: {
        '@id': String,
        '@type': String,
        id: primaryKey(String),
        name: String,
    },
    ldCampaign: {
        '@id': String,
        '@type': String,
        id: primaryKey(String),
        name: String,
    },
    ldType: {
        '@id': String,
        '@type': String,
        code: primaryKey(String),
        name: String,
    },
    ldProductType: {
        '@id': String,
        '@type': String,

        id: primaryKey(String),
        serviceType: oneOf('ldType'),
        code: String,
        name: String,
        stage: String,
        options: () => [String],
        specs: () => [String], // @Todo: use array of SpecType.jsonld-read
    },
    ldProduct: {
        '@id': String,
        '@type': String,
        id: primaryKey(String),
        name: String,
        type: oneOf('ldProductType'),
        location: oneOf('ldLocation'),
        distance: Number,
        volume: Number,
        created: String,
        updated: nullable(String),
    },
    ldService: {
        '@id': String,
        '@type': String,
        id: primaryKey(String),
        name: String,
        created: String,
        start: String,
        end: String,
        emissions: Number,
        location: nullable(oneOf('ldLocation')),
        campaign: nullable(oneOf('ldCampaign')),
        client: nullable(oneOf('ldClient')),
        products: manyOf('ldProduct'),
        type: oneOf('ldType'),
        updated: nullable(Boolean),
    },
}

export default models
