import { Db } from '../Db'

export const locations: Db['location'][] = [
    {
        id: '1ec79f72-e9eb-65dc-9945-0242ac130003',
        name: 'Nottingham',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 32.58,
        carbonEmissions: 32.58,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ea-642a-b96a-0242ac130003',
        name: 'Dagenham',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 8.56,
        carbonEmissions: 8.56,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9eb-6f00-9a16-0242ac130003',
        name: 'Accrington',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 9.11,
        carbonEmissions: 9.11,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9eb-6fa0-859a-0242ac130003',
        name: 'Ballymount',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 9.11,
        carbonEmissions: 9.11,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-602c-9a77-0242ac130003',
        name: 'Barnwood',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 1.96,
        carbonEmissions: 1.96,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-60b8-bd1d-0242ac130003',
        name: 'Bristol',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 4.28,
        carbonEmissions: 4.28,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-6144-8fe2-0242ac130003',
        name: 'Caerphilly',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 9.11,
        carbonEmissions: 9.11,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-61d0-875a-0242ac130003',
        name: 'Castleford',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 9.11,
        carbonEmissions: 9.11,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-627a-ba3e-0242ac130003',
        name: 'Droitwich',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 9.53,
        carbonEmissions: 9.53,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-6306-a2c1-0242ac130003',
        name: 'Lancing',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 8.96,
        carbonEmissions: 8.96,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-6392-aa59-0242ac130003',
        name: 'Leciester',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 17.1,
        carbonEmissions: 17.1,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-641e-a79a-0242ac130003',
        name: 'Luxembourg',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 226.58,
        carbonEmissions: 226.58,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-64b4-b4d2-0242ac130003',
        name: 'Peterborough',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 7.42,
        carbonEmissions: 7.42,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-6540-9f4e-0242ac130003',
        name: 'Redruth',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 6.01,
        carbonEmissions: 6.01,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-65cc-922c-0242ac130003',
        name: 'Rotherham',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 9.11,
        carbonEmissions: 9.11,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-6662-9d0f-0242ac130003',
        name: 'Sandyford',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 6.72,
        carbonEmissions: 6.72,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-66f8-b6c0-0242ac130003',
        name: 'Sunderland',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 56.28,
        carbonEmissions: 56.28,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-6784-8ecc-0242ac130003',
        name: 'Tewkesbury',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 4.9,
        carbonEmissions: 4.9,
        type: 'outbound',
    },
    {
        id: '1ec79f72-e9ec-6810-a8f6-0242ac130003',
        name: 'Outsourced',
        source: 'PCC own carbon calculations 2020/2021',
        emissions: 9.11,
        carbonEmissions: 9.11,
        type: 'outbound',
    },
]
