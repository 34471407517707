import { Entity } from '@mswjs/data/lib/glossary'
import { Db } from '../Db'

export const ldProducts: (
    ldLocations: Entity<Db, 'ldLocation'>[],
    ldProductTypes: Entity<Db, 'ldProductType'>[],
) => Db['ldProduct'][] = (ldLocations, ldProductTypes) => [
    {
        '@id': '/api/products/1ec79f72-e9ea-67cc-8541-0242ac130003',
        '@type': 'Product',
        id: '1ec79f72-e9ea-67cc-8541-0242ac130003',
        name: 'A4 letterhead',
        type: ldProductTypes[0],
        location: ldLocations[0],
        distance: 0,
        posted: null,
        volume: 100000,
        created: '2022-01-20T13:45:08+00:00',
        updated: null,
    },
]
