import { rest } from 'msw'

import { apiHost } from 'consts'
import db from 'MockApi/db'

const locationsHandler = rest.get(
    `${apiHost}/production_sites`,
    (_req, res, ctx) => {
        return res(ctx.json(db.location.getAll()))
    },
)

export default locationsHandler
