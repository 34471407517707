import { rest } from 'msw'
import db from 'MockApi/db'

import { apiHost } from 'consts'
import { UserRole } from 'Types/UserRole'

export const meAdminHandler = rest.get(
    `${apiHost}/users/me`,
    (_req, res, ctx) => {
        const admin = db.user
            .getAll()
            .find(u => u.roles.includes(UserRole.Admin))
        return res(ctx.status(200), ctx.json(admin))
    },
)

export const meUserHandler = rest.get(
    `${apiHost}/users/me`,
    (_req, res, ctx) => {
        const user = db.user
            .getAll()
            .find(u => u.roles.includes(UserRole.User) && u.roles.length === 1)
        return res(ctx.status(200), ctx.json(user))
    },
)

const meHandler = meAdminHandler

export default meHandler
