import { FactoryAPI } from '@mswjs/data/lib/glossary'
import { Db } from './Db'
import {
    users,
    clients,
    types,
    ldClients,
    ldTypes,
    locations,
    ldLocations,
    ldCampaigns,
    ldProducts,
    campaigns,
    products,
    services,
    ldServices,
    ldProductType,
} from './data'

export default function seed(db: FactoryAPI<Db>) {
    users.map(user => db.user.create(user))
    const seededClients = clients.map(client => db.client.create(client))
    const seededTypes = types.map(type => db.type.create(type))
    const seededLocations = locations.map(location =>
        db.location.create(location),
    )
    const seededCampaigns = campaigns.map(campaign =>
        db.campaign.create(campaign),
    )
    const seededProducts = products(seededTypes, seededLocations).map(product =>
        db.product.create(product),
    )
    services(seededClients, seededCampaigns, seededProducts, seededTypes).map(
        service => db.service.create(service),
    )

    // ld+json seeds

    const seededLdTypes = ldTypes.map(ldType => db.ldType.create(ldType))
    const seededLdClients = ldClients.map(ldClient =>
        db.ldClient.create(ldClient),
    )
    const seededLdLocations = ldLocations.map(ldLocation =>
        db.ldLocation.create(ldLocation),
    )
    const seededLdCampaigns = ldCampaigns.map(ldCampaign =>
        db.ldCampaign.create(ldCampaign),
    )
    const seededLdProductTypes = ldProductType(seededLdTypes).map(
        ldProductType => db.ldProductType.create(ldProductType),
    )
    const seededLdProducts = ldProducts(
        seededLdLocations,
        seededLdProductTypes,
    ).map(ldProduct => db.ldProduct.create(ldProduct))
    ldServices(
        seededLdClients,
        seededLdCampaigns,
        seededLdProducts,
        seededLdTypes,
    ).map(ldService => db.ldService.create(ldService))
}
