import { Entity } from '@mswjs/data/lib/glossary'
import { Db } from '../Db'

export const services: (
    clients: Entity<Db, 'client' | 'ldClient'>[],
    campaigns: Entity<Db, 'campaign' | 'ldCampaign'>[],
    products: Entity<Db, 'product' | 'ldProduct'>[],
    types: Entity<Db, 'type' | 'ldType'>[],
) => Db['service'][] = (clients, campaigns, products, types) => [
    {
        id: '1ec79f72-e9e9-6dea-8230-0242ac130003',
        location: null,
        name: 'Perspiciatis dignissimos error et itaque quibusdam.',
        start: '2004-12-24T00:00:00+00:00',
        updated: '',
        end: '2008-05-05T00:00:00+00:00',
        emissions: 0.00911,
        created: '2022-01-20T13:45:08+00:00',
        client: clients[0],
        campaign: campaigns[0],
        products: [products[0]],
        type: types[0],
    },
]
