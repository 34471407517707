import { Db } from '../Db'
import { Entity } from '@mswjs/data/lib/glossary'

export const ldProductType: (
    ldTypes: Entity<Db, 'ldType'>[],
) => Db['ldProductType'][] = ldTypes => [
    {
        /* '@id': '/api/products_types/1ec79f72-e9ea-67cc-8541-0242ac130003',
        '@type': 'ProductType',
        id: datatype.uuid,
        serviceType: types[0],
        code: '',
        name: ,
        stage: String,
        options: () => [String],
        specs: () => [String], // @Todo: use array of SpecType.jsonld-read */

        '@id': '/api/product_types/1ec79f72-e9b4-6302-aa49-0242ac130003',
        '@type': 'ProductType',
        id: '1ec79f72-e9b4-6302-aa49-0242ac130003',
        serviceType: ldTypes[0],
        code: 'letter',
        name: 'Letter',
        stage: 'production',
        options: {
            postable: true,
            has_volume: true,
        },
        specs: [[], [], []],
    },
]
