import { Db } from '../Db'

export const campaigns: Db['campaign'][] = [
    { id: '1ec79f72-e9eb-610e-ac2e-0242ac130003', name: 'Bronze', status: 1 },
    { id: '1ec79f72-e9ed-6f8a-b05a-0242ac130003', name: 'Gold', status: 1 },
    { id: '1ec79f72-e9ee-6070-b794-0242ac130003', name: 'Silver', status: 1 },
    {
        id: '1ec7a9f6-e8cc-697e-b51f-0242ac130003',
        name: 'New campaign',
        status: 1,
    },
]
