import { rest } from 'msw'

import { apiHost } from 'consts'
import {
    LoginRequest,
    LoginResponse,
} from 'Pages/UnauthenticatedApp/Login/LoginForm/useLoginMutation'

const loginHandler = rest.post<LoginRequest, LoginResponse>(
    `${apiHost}/login`,
    (_req, res, ctx) => {
        return res(
            ctx.json({
                last_username: 'User',
                roles: ['ROLE_USER'],
            }),
        )
    },
)

export default loginHandler
