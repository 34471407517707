import { datatype, name } from 'faker'
import { UserRole } from 'Types/UserRole'

export const users = [
    {
        id: datatype.uuid(),
        email: 'admin1@example.com',
        roles: [UserRole.Admin, UserRole.User],
        username: name.findName(),
    },
    {
        id: datatype.uuid(),
        email: 'user1@example.com',
        roles: [UserRole.User],
        username: name.findName(),
    },
]
