import { rest } from 'msw'

import { apiHost } from 'consts'
import { ResetPassword_PasswordResetInput } from 'generatedTypes'

const resetPasswordHandler = rest.post<ResetPassword_PasswordResetInput, {}>(
    `${apiHost}/password/reset/:code`,
    (_req, res, ctx) => {
        return res(ctx.status(204), ctx.json({ status: true }))
    },
)

export default resetPasswordHandler
