import { Entity } from '@mswjs/data/lib/glossary'
import { services } from '.'
import { Db } from '../Db'

export const ldServices: (
    clients: Entity<Db, 'ldClient'>[],
    campaigns: Entity<Db, 'ldCampaign'>[],
    products: Entity<Db, 'ldProduct'>[],
    types: Entity<Db, 'ldType'>[],
) => Db['ldService'][] = (clients, campaigns, products, types) => [
    {
        ...services(clients, campaigns, products, types)[0],
        '@context': '/api/contexts/Service',
        '@id': '/api/services',
        '@type': 'hydra:Collection',
    },
]
