import { rest } from 'msw'

import { apiHost } from 'consts'
import db from 'MockApi/db'

const campaignsHandler = rest.get(`${apiHost}/campaigns`, (_req, res, ctx) => {
    return res(ctx.json(db.campaign.getAll()))
})

export default campaignsHandler
