import { rest } from 'msw'

import { apiHost } from 'consts'
import db from 'MockApi/db'

const serviceDetailHandler = rest.get(
    `${apiHost}/services/:id`,
    (req, res, ctx) => {
        const { id } = req.params
        const services = db.service.getAll()
        const service = services.find((s) => s.id === id)

        return res(ctx.json(service))
    },
)

export default serviceDetailHandler
