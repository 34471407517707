import { rest } from 'msw'

import { apiHost } from 'consts'
import db from 'MockApi/db'

const serviceTypesHandler = rest.get(
    `${apiHost}/services/types`,
    (_req, res, ctx) => {
        return res(ctx.json(db.type.getAll()))
    },
)

export default serviceTypesHandler
