import { clients } from '.'
import { Db } from '../Db'

export const ldClients: Db['ldClient'][] = [
    ...clients.map(client => ({
        ...client,
        '@id': `/api/clients/${client.id}`,
        '@type': `Service`,
    })),
]
