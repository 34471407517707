import { rest } from 'msw'

import { apiHost } from 'consts'
import { ResetPassword_PasswordResetRequestInput } from 'generatedTypes'

const requestPasswordHandler = rest.post<
    ResetPassword_PasswordResetRequestInput,
    {}
>(`${apiHost}/password/request`, (_req, res, ctx) => {
    return res(ctx.status(202))
})

export default requestPasswordHandler
