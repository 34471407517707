import { apiHost } from 'consts'
import { ErrorType } from 'Types/Error'

interface RequestBody<D> {
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
    body?: D
    headers?: HeadersInit
}

const defaultError = 'Unknown error. Something went wrong.'
export default async function fetcher<
    ResponseData,
    BodyData = Record<string, unknown>,
>(
    endpoint: string,
    config: RequestBody<BodyData> = {
        method: 'GET',
        body: undefined,
        headers: {},
    },
): Promise<ResponseData> {
    const { method, body, headers: customHeaders } = config

    const reqConfig: RequestInit = {
        method,
        body: body ? JSON.stringify(body) : undefined,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...customHeaders,
        },
    }

    const response = await window.fetch(`${apiHost}${endpoint}`, reqConfig)

    return parseAPIResponse(response)
}

type ResponseErrorType = {
    type?: string
    title?: string
    detail?: string
    violations?: {
        code: string | null
        message: string
        propertyPath: string
    }[]
}

const extractTheErrorMessage = (parsedJSON: ResponseErrorType) => {
    if (parsedJSON.violations && parsedJSON.violations.length > 0) {
        return parsedJSON.violations.map((item) => item.message).join(' ')
    } else {
        return defaultError
    }
}

async function parseAPIResponse(response: Response) {
    const responseBody = await response.text()
    try {
        const parsedJSON = responseBody.length ? JSON.parse(responseBody) : {}

        if (response.ok) return parsedJSON
        if (response.status >= 500) {
            return Promise.reject({
                type: ErrorType.ServerError,
                status: response.status,
                body: extractTheErrorMessage(parsedJSON),
            })
        }
        if (response.status < 500) {
            if (response.status === 401) {
                return Promise.reject({
                    type: ErrorType.NetworkError,
                    status: response.status,
                    body:
                        parsedJSON && parsedJSON.detail
                            ? parsedJSON.detail
                            : defaultError,
                })
            }
            return Promise.reject({
                type: ErrorType.NetworkError,
                status: response.status,
                body: extractTheErrorMessage(parsedJSON),
            })
        }
    } catch (e) {
        // API is not properly implemented or something is not ok within the server.
        return Promise.reject({
            type: ErrorType.InvalidJSON,
            status: response.status,
            body: defaultError,
        })
    }
}
