import { campaigns } from '.'
import { Db } from '../Db'

export const ldCampaigns: Db['ldCampaign'][] = [
    ...campaigns.map(campaign => ({
        ...campaign,
        '@id': `/api/campaigns/${campaign.id}`,
        '@type': `Campaign`,
    })),
]
