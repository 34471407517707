import { Entity } from '@mswjs/data/lib/glossary'
import { Db } from '../Db'

export const products: (
    types: Entity<Db, 'type'>[],
    locations: Entity<Db, 'location'>[],
) => Db['product'][] = (types, locations) => [
    {
        id: '1ec79f72-e9ea-67cc-8541-0242ac130003',
        service: '/api/services/1ec79f72-e9db-67d6-a63d-0242ac130003',
        name: 'A4 letterhead',
        type: types[0],
        specs: [
            {
                id: '1ec79f72-e9eb-6726-9481-0242ac130003',
                created: '2022-01-20T13:45:08+00:00',
                updated: '',
                stockType: {
                    id: '1ec79f72-e9db-602e-97d9-0242ac130003',
                    name: 'Wood Coated',
                    emissions: 2.344,
                },
                size: {
                    code: 'A4',
                    width: 210,
                    height: 297,
                },
                width: 210,
                height: 297,
                printerPages: 1,
                gsm: 100,
                weight: 6.237,
                weightUnit: 'g',
                emissions: 0.01462,
                emissionUnit: 'g',
            },
        ],
        location: locations[0],
        distance: 0,
        posted: null,
        volume: 100000,
        emissions: 4719.9528,
        created: '2022-01-20T13:45:08+00:00',
        updated: '',
    },
    {
        id: '1ec79f72-e9ea-68bc-8314-0242ac130003',
        service: '/api/services/1ec79f72-e9db-67d6-a63d-0242ac130003',
        name: '20pp A5 Leaflet',
        type: types[0],
        specs: [
            {
                id: '1ec79f72-e9eb-688e-93fa-0242ac130003',
                created: '2022-01-20T13:45:08+00:00',
                updated: '',
                stockType: {
                    id: '1ec79f72-e9db-602e-97d9-0242ac130003',
                    name: 'Wood Coated',
                    emissions: 2.344,
                },
                size: {
                    code: 'A5',
                    width: 148,
                    height: 210,
                },
                width: 148,
                height: 210,
                printerPages: 20,
                gsm: 200,
                weight: 124.32,
                weightUnit: 'g',
                emissions: 0.29141,
                emissionUnit: 'g',
            },
            {
                id: '1ec79f72-e9eb-692e-988d-0242ac130003',
                created: '2022-01-20T13:45:08+00:00',
                updated: '',
                stockType: {
                    id: '1ec79f72-e9db-60b0-a602-0242ac130003',
                    name: 'Wood Uncoated',
                    emissions: 2.079,
                },
                size: {
                    code: 'A5',
                    width: 148,
                    height: 210,
                },
                width: 148,
                height: 210,
                printerPages: 16,
                gsm: 130,
                weight: 64.6464,
                weightUnit: 'g',
                emissions: 0.1344,
                emissionUnit: 'g',
            },
        ],
        location: locations[0],
        distance: 0,
        posted: null,
        volume: 100000,
        emissions: 45838.59456,
        created: '2022-01-20T13:45:08+00:00',
        updated: '',
    },
]
